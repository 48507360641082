import React from 'react';
import ArticleCard from './ArticleCard/ArticleCard';
import styles from './RelatedArticles.module.scss';

const RelatedArticles = ({ articles }) => (
  <>
  <h3 className={styles['related-articles-heading']}>Related Articles</h3>
  <section className={styles["related-articles"]}>
    {articles.map((article, i) => (
      <ArticleCard {...article.node} key={i} />
    ))}
  </section>
  </>
);

export default RelatedArticles;

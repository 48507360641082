// @flow strict
import React from 'react';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import styles from './Content.module.scss';

type Props = {
  body: string,
  title: string,
  image: string
};

const Content = ({ body, title, image }: Props) => (
  <div className={styles['content']}>
    <BackgroundImage
      Tag="section"
      className={styles['content__header']}
      fluid={image.childImageSharp.fluid}
    >
      <div className={styles['content__layer']}>
        <h1 className={styles['content__title']}>{title}</h1>
      </div>
    </BackgroundImage>
    <div>&nbsp;</div>
    <div className={styles['content__body']} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

export default Content;

/*
const Content = ({ body, title, image }: Props) => (
  <div className={styles['content']}>
    <header
      className={styles['content__header']}
      style={{backgroundImage: `url(${image.childImageSharp.fluid.src})`
    }}>
      <h1 className={styles['content__title']}>{title}</h1>
    </header>
    <div>&nbsp;</div>
    <div className={styles['content__body']} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);
*/

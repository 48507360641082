import path from 'path';
import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import * as config from '../../../../config';
import TextCleaner from 'text-cleaner';

const ArticleSchema = ({ slug, title, description, template, date, image, content, category, wordCount }) => {

  const regexContent = content.replace(/<[^>]+>|\>|\`.*?|\*|\n|\r|\t|\!\[.*\)|\-\s{1}|\[[A-Za-z_\-\s0-9]+|\]|\#[A-Za-z_\-\s0-9]+|\(|\)|\#/g, '');
  const cleanText = TextCleaner(regexContent).stripHtml().condense().valueOf();
  const { url, author } = config;
  const schemaOrgJSONLD = [
    {
      '@context': 'https://schema.org',
      '@type': 'Article',
      'mainEntityOfPage': {
        '@type': 'WebPage',
        '@id': `${url}${slug}`,
        'speakable': {
        	'@type': 'SpeakableSpecification',
          'cssSelector': [
            'Content-module--content__title--1qFLI',
            'Content-module--content__body--2bfha'
          ]
        }
      },
      'headline': title,
      'description': description,
      'datePublished': date,
      'articleBody': cleanText,
      'articleSection': category,
      'wordCount': wordCount,
      'image': {
        '@type': 'ImageObject',
        'url': `${url}${image}`,
      },
      'publisher': {
        '@type': 'Organization',
        'name': `${author.name}`,
        'logo': {
          '@type': 'ImageObject',
          'url': `${url}${author.photo}`
        }
      },
      'author': {
        '@type': 'Person',
        'name': `${author.name}`,
        'sameAs': [
          `https://www.facebook.com/${author.contacts.facebook}`,
          `https://twitter.com/${author.contacts.twitter}`,
          `https://www.linkedin.com/${author.contacts.linkedin}`,
          `https://github.com/${author.contacts.github}`,
          `https://www.instagram.com/${author.contacts.instagram}`
        ],
      }
    }
  ];

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  );
};

export default ArticleSchema;

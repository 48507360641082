// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Author from './Author';
import Comments from './Comments';
import Content from './Content';
import Meta from './Meta';
import Tags from './Tags';
import RelatedArticles from '../RelatedArticles/RelatedArticles'
import Footer from '../Footer/Footer'
import styles from './Post.module.scss';
import type { Node } from '../../types';
import ArticleSchema from './Schema/ArticleSchema';

type Props = {
  post: Node
};

// destructure { articles } as well as post for relatedArticles
const Post = ({ post, related }: Props) => {
  const { html } = post;
  const { internal: { content } } = post;
  const { wordCount: { words } } = post;
  const { tagSlugs, slug } = post.fields;
  const { title, date, description, category, tags, cover_image, socialImage } = post.frontmatter;
  // Extracts the relatedArticles from the Context API

  return (
      <div className={styles['post']}>
        <ArticleSchema
          slug={slug}
          title={title}
          description={description}
          date={date}
          image={socialImage}
          content={content}
          category={category}
          wordCount={words}
        />
        <div className={styles['post__content']}>
          <Content body={html} title={title} image={cover_image} />
        </div>

        <div className={styles['post__footer']}>
          <Meta date={date} />
          {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
          <Author />
        </div>

        <div className={styles['post__comments']}>
          <Comments postSlug={slug} postTitle={post.frontmatter.title} />
        </div>

        <div>
          {related.edges.length && <RelatedArticles articles={related.edges} /> || null }
        </div>
      </div>
  );
};

export default Post;

'use strict';

module.exports = {
  url: 'https://www.oliverhayman.com',
  pathPrefix: '/',
  title: 'Oliver Hayman',
  subtitle: 'Human tech person, writing about SEO, Analytics, Data & Python.',
  copyright: '© All rights reserved.',
  disqusShortname: '',
  postsPerPage: 4,
  googleAnalyticsId: 'UA-116374958-1',
  useKatex: true,
  menu: [
    {
      label: 'Articles',
      path: '/'
    },
    {
      label: 'About',
      path: '/pages/about'
    },
    {
      label: 'Contact',
      path: '/pages/contact'
    }
  ],
  author: {
    name: 'Oliver Hayman',
    photo: '/photo-4.jpg',
    bio: 'Human tech person, writing about SEO, Analytics, Data & Python.',
    contacts: {
      email: 'ollie.hayman7@gmail.com',
      facebook: 'oliver.hayman.5',
      telegram: '',
      twitter: 'OliverHayman1',
      github: 'scroobiustrip',
      rss: '',
      vkontakte: '',
      linkedin: 'oliver-hayman-08971a57/',
      instagram: 'yesclemfandango/',
      line: '',
      gitlab: '',
      weibo: '',
      codepen: '',
      youtube: '',
      soundcloud: '',
    }
  }
};

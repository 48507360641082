import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styles from './ArticleCard.module.scss'

const ArticleCard = ({ frontmatter, fields }: Props) => {

  const { title, description, date, category, cover_image, socialImage } = frontmatter;
  const { slug, categorySlug } = fields;

  return (
    <article className={styles['article-card']}>
      <Link to={slug}>
        <BackgroundImage
          className={styles['article-card__image']}
          fluid={cover_image.childImageSharp.fluid}
        >
          <div className={styles['article-card__layer']}>
            <div>&nbsp;</div>
            <Link className={styles['article-card__category']} to={`${categorySlug}`}>{category}</Link>
            <Link  to={slug}>
              <h4 className={styles['article-card__title']}>{title}</h4>
              <p className={styles['article-card__date']}>{date.split('T')[0]}</p>
            </Link>

          </div>
        </BackgroundImage>
      </Link>
    </article>
  );
}

export default ArticleCard;


/*
<Link className={styles['article-card__category']} to={`${categorySlug}`}>{category}</Link>
<Link className={styles['article-card__title']} to={slug}>
  <h4>{title}</h4>
  <p>{date.split('T')[0]}</p>
</Link>
*/

// @flow strict
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Post from '../components/Post';
import { useSiteMetadata } from '../hooks';
import type { MarkdownRemark } from '../types';

type Props = {
  data: {
    post: MarkdownRemark,
    related: allMarkdownRemark,
  }
};


// Renaming the main query from markdownRemark to "post"
// Need to change the variables to now reflect that these are using "post"
// Then pass down the data from "related" to be used in the <RelatedArticles /> component

// Destructure out { pageContext } as well as data for relatedArticles
const PostTemplate = ({ data }: Props) => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();
  const { frontmatter } = data.post;
  const { title: postTitle, description: postDescription, socialImage } = frontmatter;
  const metaDescription = postDescription !== null ? postDescription : siteSubtitle;
  // const { relatedArticles } = data.related.edges;

  return (
    <Layout title={`${postTitle} - ${siteTitle}`} description={metaDescription} socialImage={socialImage}>
      <Post post={data.post} related={data.related} />
    </Layout>
  );
};

export const query = graphql`
  query PostBySlug($slug: String!, $category: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      internal {
        content
      }
      wordCount {
        words
      }
      fields {
        slug
        tagSlugs
      }
      frontmatter {
        template
        date
        description
        category
        tags
        title
        socialImage
        cover_image {
          childImageSharp {
            fluid(maxWidth: 1600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    related: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC},
      limit: 3,
      filter: {
        frontmatter: {
          category: {
            eq: $category
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            description
            category
            socialImage
            cover_image {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
            categorySlug
          }
        }
      }
    }
  }
`;

export default PostTemplate;
